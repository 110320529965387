.nest-wifi-star-rating-container-wrapper {
    display:flex;
    margin-top:7px;
  }
  
  .nest-wifi-star-rating-container {
    display:flex;
  }
  
  .nest-wifi-star-rating-shape {
    width:16px;
    height:16px;
    margin-right:2px;
    background-position: center;
    background-size:contain;
    background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24' fill='%23E37400'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z'/%3E%3C/svg%3E");
  }
  
  .full-star {
    background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24' fill='%23E37400'%3E%3Cpath d='M0 0h24v24H0V0zm0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z'/%3E%3C/svg%3E");
  }
  
  .half-star {
    background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24' fill='%23E37400'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4V6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z'/%3E%3C/svg%3E");
  }
  
  .nest-wifi-star-rating-reviews {
    font-size:12px;
    line-height:16px;
    margin-left:3px;
    color:#5f6368;
    white-space:nowrap;
  }

.nest-wifi-nav-container-wrapper {
  height:82px;
}

.nest-wifi-nav-container {
    /*position: sticky;*/
    position:relative;
    top:0;
    left:0;
    padding:0 24px;
    z-index: 92;
    background-color:#fff;
    /*opacity:0;*/
}

.nest-wifi-nav {
  max-width: 1440px;
  margin:auto;
  display:flex;
  justify-content: space-between;
  height:82px;
}
  
  .shadow {
    position: fixed;
    top:0;
    left:0;
    width:100%;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 15%);
  }
  
  /*
  .nest-wifi-nav {
    display:flex;
    justify-content: space-between;
    height:82px;
  }
  */

  .nest-wifi-nav-heading-container {
    /*border:1px solid red;*/
    display: flex;
    align-items: center;
  }
  
  .nest-wifi-nav-heading {
    white-space: nowrap;
    padding:8px 0;
    font-size: 24px;
    line-height:16px;
    color:#423f3f;
  }
  
  .nest-wifi-nav-options-container-wrapper {
    margin-right:auto;
    padding-left:24px;
    display:flex;
  }
  
  .nest-wifi-nav-options-desktop-container {
    display:flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
    /*border:1px solid blue;*/
    height:100%;
    margin:auto 8px;
  }

  .nest-wifi-nav-options-mobile-container {
    position: relative;
    display:flex;
    align-items: center;
    height:100%;
  }

  .nest-wifi-nav-options-selected-item-mobile {
    position:relative;
    display: flex;
    align-items: center;
    padding:0 11px 0 15px;
    border-radius:36px;
    height:32px;
    border:1px solid #dadce0;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0 1px 4px 0 rgb(5 5 5 / 15%);
    transition:border .7s ease;
    cursor: pointer;
  }

  /*previous version for hover of mobile dropdown options btn
  .nest-wifi-nav-options-selected-item-mobile:hover .nest-wifi-nav-options-selected-item-text-mobile{
    color:#174ea6;
  }

  .nest-wifi-nav-options-selected-item-mobile:hover {
    background-color: rgb(237, 245, 253);
  }
  */

  .mobile-option-item-clicked {
    border:1px solid #174ea6;
    /*background-color: rgb(214, 230, 253);*/
  }

  .nest-wifi-nav-options-selected-item-cover {
    position:absolute;
    top:0;
    bottom:0;
    opacity:1;
    background-color:#1a73e8;
    border-radius:36px;
  }

  .ripple-both {
    left:50%;
    width:1px;
    animation: rippleBoth .2s;
    animation-fill-mode: forwards;
  }

  @keyframes rippleBoth {
    from {transform:scaleX(1); opacity:0;}
    to {transform:scaleX(var(--ripple-both)); opacity:0.3;}
  }

  .ripple-left {
      right:-1px;
      animation: rippleLeft .2s;
      animation-fill-mode: forwards;
  }

  @keyframes rippleLeft {
      from {width:0; opacity:0;}
      to {width:102%; opacity:0.3;}
  }

  .ripple-right {
      left:-1px;
      animation: rippleRight .2s;
      animation-fill-mode: forwards;
  }

  @keyframes rippleRight {
      from {width:0; opacity:0;}
      to {width:102%; opacity:0.3;}
  }

  .nest-wifi-nav-options-selected-item-text-mobile {
    min-width:88px;
    font-weight: 600;
    font-size:.875rem;
    letter-spacing: .01rem;
    color:#423f3f;
  }

  .mobile-option-item-text-clicked {
    color:#174ea6;
  }

  .nest-wifi-options-selected-svg-mobile {
    width:24px;
    height:24px;
    transform: rotate(45deg) translate(-10px, -10px);
  }

  .nest-wifi-nav-options-list-mobile {
    display:block;
    position:absolute;
    background-color: #fff;
    left:0;
    right:0;
    top:0;
    padding:0;
    list-style-type: none;
    margin-bottom: 0;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
    padding:4px 0;
    border-radius: 4px;
    visibility: visible;
    transform:scale(1);
    transform-origin: center top;
    opacity: 1;
    transition: opacity .12s ease, transform .12s ease;
  }

  .nest-wfif-nav-option-item-container-mobile {
    height:40px;
    padding:0 16px;
    display:flex;
    align-items: center;
    /*border:1px solid red;*/
    background-color: #fff;
    cursor: pointer;
  }

  .nest-wfif-nav-option-item-container-mobile:hover {
    background-color: #f2f2f2;
  }

  .nest-wfif-nav-option-item-mobile {
    font-size: 14px;
    font-weight: 400;
  }

  .change-nest-wifi-nav-option-item-container-background-mobile {
    background-color: #f2f2f2;
  }
  
  .nest-wfif-nav-option-btn {
    margin:0 8px;
    padding:8px;
    color:#423f3f;
    background-color:#fff;
    border-radius:16px;
    border:0;
    cursor: pointer;
    white-space: nowrap;
  }
  
  .nest-wfif-nav-option-btn:hover {
    background-color:#f7f7f7;
  }
  
  .change-nest-wifi-nav-option-btn-background {
    background-color:#fff;
    box-shadow: 0 1px 4px 0 rgb(5 5 5 / 15%);
    text-shadow: 0 0 #423f3f;
  }
  
  .change-nest-wifi-nav-option-btn-background:hover {
    background-color:#fff;
  }
  
  .nest-wifi-nav-pricing-container-wrapper {
    display:flex;
  }
  
  .nest-wifi-nav-pricing-container {
    /*border:1px solid lime;*/
    min-width:160px;
    margin:0 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 8px;
  }
  
  .nest-wifi-nav-buy-btn-container {
    /*border:1px solid purple;*/
    display: flex;
    align-items: center;
    padding: 0 0 0 8px;
  }
  
  .nest-wifi-nav-pricing-line-one {
    font-size: 14px;
    line-height:18px;
    margin-bottom: 4px;
    text-align: right;
  }
  
  .nest-wifi-nav-pricing-line-two {
    color:#423f3f;
    font-size:12px;
    line-height:12px;
    text-align:right;
    font-weight: 500;
    margin-bottom: 4px;
  }
  
  .nest-wifi-nav-buy-btn {
    font-size:22px;
    background-color:#1a73e8;
    color:#fff;
    min-width: 76px;
    height:56px;
    border-radius: 4px;
    font-weight: 500;
    margin:8px 0;
    border:none;
    padding:0 45px;
    cursor: pointer;
  }

  .nest-wifi-nav-container-mobile {
    display:flex;
    justify-content: space-between;
    position: fixed;
    bottom:0;
    left:0;
    right:0;
    max-height:138px;
    min-height:48px;
    height:auto;
    padding:4px;
    background-color: #fff;
    box-shadow: 0 -2px 10px 0 rgb(5 5 5 / 15%);
    z-index:20;
    /*opacity:1;*/
  }

  .nest-wifi-nav-financing-container-mobile {
    margin:auto 0;
  }

  .nest-wifi-nav-financing-terms-container-mobile {
    line-height:8px;
    margin:auto 8px;
  }

  .nest-wifi-nav-financing-term1-container-mobile {
    margin-bottom:4px;
  }

  .nest-wifi-nav-buy-btn-container-mobile {
    margin:5px 0;
    padding-left:8px;
    border:none;
  }

  .nest-wifi-nav-buy-btn-mobile {
    font-size:14px;
    padding:0 20px;
    height:fit-content;
    min-height:40px;
    margin:4px 0;
    background-color: #1a73e8;
    color:#fff;
    white-space: nowrap;
    min-width:76px;
    width:fit-content;
    border:none;
    border-radius:4px;
    font-weight:500;
  }

  .hide-nest-wifi-nav-options {
    opacity:0;
    transform:scale(0.7);
    visibility:hidden;
  }

  @media screen and (min-width:761px){
    .nest-wifi-nav-container-mobile {
      display:none;
    }

    /*
    .nest-wifi-nav-options-mobile-container {
      display:none;
    }
    */
  }

  @media screen and (min-width:960px){
    .nest-wifi-nav-options-mobile-container {
      display:none;
    }
  }

  @media screen and (max-width:1144px){
    .nest-wifi-star-rating-container-wrapper {
      display:none;
    }

    .nest-wifi-nav-heading {
      font-size:20px;
    }
  }

  @media screen and (max-width:959px){
    .nest-wifi-nav-options-desktop-container {
      display:none;
    }
  }

  @media screen and (max-width:760px){
    /*
    .nest-wifi-nav-options-desktop-container {
      display:none;
    }
    */
    .nest-wifi-nav-container-wrapper {
      /*original version
      /*height:unset;*/
      height:48px
    }

    .nest-wifi-nav-container {
      padding:0 10px;
      opacity:1;
    }

    .nest-wifi-nav {
      /*original version
      /*height:unset;*/
      height:48px;
    }

    .nest-wifi-nav-options-container-wrapper {
      margin-left:auto;
      margin-right:unset;
    }

    .nest-wifi-nav-pricing-container-wrapper {
      display:none;
    }

    .nest-wifi-nav-financing-terms-container-mobile {
      min-width:unset;
    }

    .nest-wifi-nav-financing-term1-container-mobile {
      font-size:13px;
      line-height:13px;
      white-space:normal;
      text-align:left;
    }

    .nest-wifi-nav-financing-term2-container-mobile {
      font-size:13px;
      line-height:13px;
      text-align:left;
      font-weight:500;
    }

    .nest-wifi-nav-heading {
      font-size:16px;
    }

  }