* {
  margin:0;
  padding:0;
  box-sizing: border-box;
}

.nest-wifi-location-info-container {
  position: relative;
  background-color:#1967d2;
  color:#fff;
  z-index: 92;
  display:none;
}

.nest-wifi-promo-text-wrapper {
  margin:0 0 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nest-wifi-promo-text {
  /*border:2px solid red;*/
  display:inline-block;
  padding:16px 0;
  font-size:16px;
  line-height: 16px;
}

.nest-wifi-promo-text-link {
  color:white;
  padding-left:3px;
}

/*google nav styles
.nest-wifi-google-store-nav-wrapper {
  position:relative;
  padding:0 20px;
  width:100%;
  height:56px;
  display:flex;
  justify-content: space-between;
  z-index: 92;
  background-color:#fff;
}

.nest-wifi-google-store-nav-options-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nest-wifi-google-store-logo-container{
  width:134px;
  height:24px;
  display: inline-block;
  cursor: pointer;
}

.nest-wifi-google-store-options-container {
  display:flex;
  margin-left:24px;
}

.nest-wifi-google-store-option-link {
  text-decoration: none;
  cursor: pointer;
}

.nest-wifi-google-store-option-link-container:nth-child(n + 2){
  margin-left:20px;
}

.nest-wifi-google-store-option {
  color:#3c4043;
  line-height:20px;
  font-size:13px;
  font-weight:500;
  padding-top:5px;
  background-color:transparent;
  border:none;
  cursor: pointer;
}

.nest-wifi-google-store-option:hover, .nest-wifi-google-store-option:focus {
  color:#1967d2;
}

.nest-wifi-google-store-option-link-container:nth-child(-n + 5) .nest-wifi-google-store-option::after {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M18.59 16.41L20 15l-8-8-8 8 1.41 1.41L12 9.83'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cg mask='url(%23b)' fill='%235f6368'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  transform:rotate(180deg);
  display:inline-block;
  width:12px;
  height:12px;
  margin-left:4px;
  vertical-align: middle;
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center center;
  transition:transform .35s ease;
}

.nest-wifi-google-store-option-link-container:nth-child(-n + 5) .nest-wifi-google-store-option:hover::after,
.nest-wifi-google-store-option-link-container:nth-child(-n + 5) .nest-wifi-google-store-option:focus::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M18.59 16.41L20 15l-8-8-8 8 1.41 1.41L12 9.83'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cg mask='url(%23b)' fill='%231967d2'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.nest-wifi-google-store-option-link-container:nth-child(-n + 5) .up-arrow::after {
  transform:rotate(0deg);
}


.nest-wifi-google-store-option-sub-menu-container {
  position:absolute;
  left:calc(50% - 50%);
  padding-top:32px;
  width:100%;
  z-index: 1;
  background-color:#fff;
  cursor:initial;
}

.inactive-option {
  display:none;
  top:-1000px;
}

.active-option {
  display:block;
  top:56px;
}

.nest-wifi-google-store-option-sub-menu {
  display:flex;
  width:100%;
  min-height:448px;
  z-index:1;
  padding-left:50px;
  padding-right:20px;
  background-color:#fff;
}

.nest-wifi-goolge-store-option-sub-menu-overview-container {
  display:flex;
  width:64%;
  margin-right:32px;
}

.nest-wifi-google-store-option-sub-menu-overview {
  display:flex;
  flex-direction: column;
  width:100%;
}

.nest-wifi-google-store-option-sub-menu-overview-heading-container {
  display:flex;
  width:100%;
  justify-content: space-between;
  flex-wrap:wrap;
  align-items: center;
  margin-bottom:32px;
}

.nest-wifi-google-store-option-sub-menu-overview-heading-wrapper {
  display:flex;
  flex-direction: column;
  padding:8px 0;
  margin:auto 0;
}

.nest-wifi-google-store-option-sub-menu-overview-heading {
  font-size:18px;
  line-height:36px;
  font-weight: 500;
}
*/

/* nest wifi nav styles
.nest-wifi-star-rating-container-wrapper {
  display:flex;
  margin-top:7px;
}

.nest-wifi-star-rating-container {
  display:flex;
}

.nest-wifi-star-rating-shape {
  width:16px;
  height:16px;
  margin-right:2px;
  background-position: center;
  background-size:contain;
  background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24' fill='%23E37400'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z'/%3E%3C/svg%3E");
}

.full-star {
  background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24' fill='%23E37400'%3E%3Cpath d='M0 0h24v24H0V0zm0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z'/%3E%3C/svg%3E");
}

.half-star {
  background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24' fill='%23E37400'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4V6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z'/%3E%3C/svg%3E");
}

.nest-wifi-star-rating-reviews {
  font-size:12px;
  line-height:16px;
  margin-left:3px;
  color:#5f6368;
  white-space:nowrap;
}
*/


/*google nav styles
.nest-wifi-google-store-option-sub-menu-overview-heading-link {
  text-decoration: none;
  color:#3c4043;
}

.nest-wifi-google-store-option-sub-menu-overview-heading-btn-link {
  text-decoration: none;
}

.nest-wifi-google-store-option-sub-menu-overview-heading-btn {
  display:flex;
  height:36px;
  padding:0 20px;
  align-items: center;
  border-radius:4px;
  border:2px solid #dadce0;
  background-color:transparent;
  cursor: pointer;
}

.nest-wifi-google-store-option-sub-menu-overview-heading-btn:hover {
  border:2px solid #1967d2;
  color:#1967d2;
} 

.nest-wifi-google-store-sub-menu-overview-heading-spacer {
  width:100%;
  height:1px;
  margin-top:16px;
  background-color:#dadce0;
}

.nest-wifi-google-store-sub-menu-overview-column-container {
  display:grid;
  grid-template-columns: auto auto auto;
  grid-gap: 0 32px;
  width:100%;
}

.nest-wifi-google-store-sub-menu-overview-column {
  padding-bottom:32px;
}

.nest-wifi-google-store-sub-menu-overview-column:nth-child(2) .nest-wifi-google-store-sub-menu-overview-sub-column:nth-child(n + 2) {
  margin-top:24px;
}

.nest-wifi-google-store-sub-menu-overview-sub-column {
  padding:0;
  margin:0;
  list-style-type: none;
}

.nest-wifi-google-store-sub-menu-overview-sub-column-heading {
  font-size:11px;
  margin-bottom:16px;
  color:#3c4043;
}

.nest-wifi-google-store-sub-menu-overview-sub-column-item {
  font-size:13px;
  margin-bottom:16px;
  color:#3c4043;
}

.nest-wifi-google-store-sub-menu-overview-sub-column-item:hover {
  cursor: pointer;
  color:#1967d2;
}

.sub-column-item-status {
  display:inline-block;
  text-transform: uppercase;
  margin-left:8px;
  font-size: 11px;
  line-height: 16px;
  font-weight: 700;
  color:#1967d2;
}

.nest-wifi-google-store-options-sub-menu-other-options-container {
  padding:75px 48px 32px;
  background-color:#f8f9fa;
  flex-grow:1;
}

.nest-wifi-google-store-options-sub-menu-other-options {
  padding:0;
  margin:0;
  list-style-type: none;
}

.nest-wifi-google-store-options-sub-menu-other-option {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom:16px;
  color:#3c4043;
}

.nest-wifi-google-store-options-sub-menu-other-option:hover {
  cursor: pointer;
  color:#1967d2;  
}


.nest-wifi-google-store-nav-other-options-container {
  display:flex;
  align-items: center;
  justify-content: center;
}

.nest-wifi-google-store-other-option-link {
  cursor: pointer;
  display: inline-block;
  margin-left:24px;
  text-decoration: none;
}

.nest-wifi-google-store-other-option {
  width:24px;
  height:24px;
  cursor: pointer;
  color:#5f6368;
  fill: #5f6368;
}

.nest-wifi-google-store-email-btn-container {
  margin-left:24px;
}

.google-email-btn {
  border:none;
  padding:0;
  cursor: pointer;
  background-color:grey;
  margin-top:1px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.google-email-img {
  border-radius: 50%;
}
*/

/*nest wifi nav styles
.nest-wifi-nav-container {
  position:relative;
  top:0;
  left:0;
  padding:0 24px;
  z-index: 92;
  background-color:#fff;
}

.shadow {
  position: fixed;
  top:0;
  left:0;
  width:100%;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 15%);
}

.nest-wifi-nav {
  display:flex;
  justify-content: space-between;
  height:82px;
}

.nest-wifi-nav-heading-container {
  display: flex;
  align-items: center;
}

.nest-wifi-nav-heading {
  padding:8px 0;
  font-size: 24px;
  line-height:16px;
  color:#423f3f;
}

.nest-wifi-nav-options-container-wrapper {
  margin-right:auto;
  padding:0 0 0 24px;
}

.nest-wifi-nav-options-container {
  display:flex;
  align-items: center;
  height:100%;
  margin:0 8px;
}

.nest-wfif-nav-option-btn {
  margin:0 8px;
  padding:8px;
  color:#423f3f;
  background-color:#fff;
  border-radius:16px;
  border:0;
  cursor: pointer;
}

.nest-wfif-nav-option-btn:hover {
  background-color:#f7f7f7;
}

.change-nest-wifi-nav-option-btn-background {
  background-color:#fff;
  box-shadow: 0 1px 4px 0 rgb(5 5 5 / 15%);
  text-shadow: 0 0 #423f3f;
}

.change-nest-wifi-nav-option-btn-background:hover {
  background-color:#fff;
}

.nest-wifi-nav-pricing-container-wrapper {
  display:flex;
}

.nest-wifi-nav-pricing-container {
  min-width:160px;
  margin:0 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 8px;
}

.nest-wifi-nav-buy-btn-container {
  display: flex;
  align-items: center;
  padding: 0 0 0 8px;
}

.nest-wifi-nav-pricing-line-one {
  font-size: 14px;
  line-height:18px;
  margin-bottom: 4px;
  text-align: right;
}

.nest-wifi-nav-pricing-line-two {
  color:#423f3f;
  font-size:12px;
  line-height:12px;
  text-align:right;
  font-weight: 500;
  margin-bottom: 4px;
}

.nest-wifi-nav-buy-btn {
  font-size:22px;
  background-color:#1a73e8;
  color:#fff;
  min-width: 76px;
  height:56px;
  border-radius: 4px;
  font-weight: 500;
  margin:8px 0;
  border:none;
  padding:0 45px;
  cursor: pointer;
}
*/

/*video devices colors component styles
.video-devices-colors-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video-devices-colors-msg-container-wrapper {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin:2vw 0 0;
  padding:0 12px;
  text-align: center;
  width:100%;
}

.video-devices-colors-msg-container {
  margin-bottom:20px;
  font-size: 40px;
  line-height: 54px;
  width:100%;
  font-weight: 600;
  opacity:0;
  transform:translateY(30%);
}

.video-devices-colors-btn-container {
  width:100%;
  opacity:0;
  transform:translateY(30%);
}

.slide-up-video-devices-colors-msg-container {
  transform:translateY(0);
  opacity:1;
  transition:transform .6s .15s cubic-bezier(0.4, 0, 0.2, 1), opacity .6s .15s cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-up-video-devices-colors-btn-container {
  transform:translateY(0);
  opacity:1;
  transition:transform .6s .3s cubic-bezier(0.4, 0, 0.2, 1), opacity .6s .3s cubic-bezier(0.4, 0, 0.2, 1);
}

.video-devices-colors-btn-link {
  display:inline-flex;
  position:relative;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 3px;
  border:2px solid #1967D2;
  padding:6px 12px;
  background-color:transparent;
  font-weight: 500;
  line-height: 1.95312vw;
  font-size:1.36719vw;
}

.video-devices-colors-btn-link::before {
  content:"";
  position: absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background-color:#1967D2;
  border-radius: 3px;
  transform:scale(.9, .65);
  opacity:0;
}

.video-devices-colors-btn-link:hover {
  border:2px solid transparent;
}

.video-devices-colors-btn-link:hover::before {
  content:"";
  position: absolute;
  transform-origin: center center;
  top:-4px;
  left:-2px;
  right:-2px;
  bottom:-4px;
  opacity: 1;
  background-color:#1967D2;
  transform:scale(1, .9);
  transition:all .25s ease;
}

.video-devices-colors-btn-link:hover .video-devices-colors-btn {
  color:#fff;
  background-color:#1967D2;
}

.video-devices-colors-btn {
  color:#1967D2;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color:transparent;
  opacity:1;
  z-index: 1;
}
*/

/*
.change-video-devices-colors-btn {
  transform: scale(1, 1);
  opacity: 1;
  background-color:#1967D2;
  border:2px solid transparent;
  color:#fff;
  transition:background-color .25s cubic-bezier(0.1, 0.1, 0, 1), transform .25s cubic-bezier(0.1, 0.1, 0, 1), opacity .25s cubic-bezier(0.1, 0.1, 0, 1);
}
*/

/*video devices moving component styles
.video-devices-moving-container-wrapper {
  position: relative;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.video-devices-colors-video-container {
  position:relative;
  width:80%;
  margin-top:40px;
}

.video-devices-moving-container {
  width:100%;
  position:relative;
  filter:brightness(var(--darken));
}

.video-devices-colors-video, .video-devices-moving {
  position: absolute;
  width:100%;
  height:100%;
  object-fit: cover;
}

.video-devices-colors-svg, .video-devices-moving-svg {
  width:100%;
}

.video-devices-moving-msg-container-wrapper {
  position: absolute;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width:60vw;
  max-width: 750px;
  height:100%;
}

.video-devices-moving-msg-container {
  max-width: 680px;
  line-height: 3.125vw;
  font-size:2.34375vw;
  color:white;
  font-weight:600;
  text-align: center;
  margin-bottom:15px;
  border:2px solid yellow;
  transform:translateY(30%);
  transition:transform .6s .3s cubic-bezier(0.4, 0, 0.2, 1), opacity .6s .3s cubic-bezier(0.4, 0, 0.2, 1);
  opacity:0;
}

.video-devices-moving-msg {
  display: inline-block;

}

.video-devices-moving-btn-container-wrapper {
  margin-top: 10px;
  border:2px solid orange;
  display: flex;
  justify-content: center;
  width:100%;
  transform:translateY(30%);
  transition:transform .6s .15s cubic-bezier(0.4, 0, 0.2, 1), opacity .6s .15s cubic-bezier(0.4, 0, 0.2, 1);
  opacity:0;
}

.slide-up-video-devices-moving-msg-container {
  transform:translateY(0);
  opacity:1;
  transition:transform .6s .15s cubic-bezier(0.4, 0, 0.2, 1), opacity .6s .15s cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-up-video-devices-moving-btn-container {
  transform:translateY(0);
  opacity:1;
  transition:transform .6s .3s cubic-bezier(0.4, 0, 0.2, 1), opacity .6s .3s cubic-bezier(0.4, 0, 0.2, 1);
}

.video-devices-moving-btn-container {
  color:#fff;
  position:relative;
}

.video-devices-moving-btn-svg {
  height:70px;
  width:70px;
  border-radius: 100%;
  cursor: pointer;
}

.video-devices-moving-btn-svg circle {
  opacity:1;
  color:#fff;
}
*/

/*room wifi videos component styles
.video-parent-wrapper {
  position: relative;
  width:100%;
  height:400vh;
  background-color: lightpink;
}

.video-child-wrapper {
  position:sticky;
  top:0;
  left:0;
  width:100%;
  height:1012px;
  background-color:#F8F8F8;
  transform:translateY(var(--video-room-intro-shift-y));
  transition-timing-function: cubic-bezier(1, .1, 0.96, .61);
  transition: transform .3s;
  will-change: transform;
  border:2px solid red;
  overflow: hidden;
}

.video-rooms-container {
  position: absolute;
  top:0;
  left:0;
  height:1012px;
  width:100%;
  clip-path:inset(var(--single-video-clip-top) var(--single-video-clip-x) var(--single-video-clip-bottom) var(--single-video-clip-x));
  transition: clip-path .3s ease;

}

.video-single-room-container {
  height:949px;
  position: absolute;
  width:100%;
  top:0;
  left:0;
  transform:translateY(var(--single-video-y)) scale(var(--single-video-scale));
  transform-origin: 50% var(--single-video-origin);
  opacity:var(--single-video-opacity);
  transition: transform .3s ease, transform-origin .3s ease, opacity .3s ease;
  will-change: transform, transform-origin, opacity;
}

.video-all-room-container {
  height:949px;
  position: absolute;
  width:100%;
  top:0;
  left:0;
  transform:translateY(68.75px) scale(1);
  transform-origin: 50% 50%;
  opacity:var(--all-video-opacity);
  will-change: transform;
}

.video-room-shape {
  object-fit: cover;
  width:100%;
  height:100%;
}

.video-room-header-container {
  position:absolute;
  padding:60px 60px 40px;
  left:0;
  top:70px;
  right:0;
  transform:translateY(var(--video-room-header-y));
  transition-timing-function: cubic-bezier(.41, .28, .71, .13);
  transition:transform .3s;
  will-change: transform;
  opacity:1;
}

.video-room-header-text {
  font-size: 2.3vw;
  font-weight: 600;
  margin:0 0 20px;
  text-align: center;
}

.video-room-msg-wrapper {
  position:absolute;
  top:95px;
  width:100%;
  left:0;
}

.video-room-spacer {
  position:relative;
  max-width:600px;
  margin:0 auto;
}

.video-single-room-msg-container, .video-all-room-msg-container {
  position: absolute;
  top:0;
  width:100%;
  padding:80px 20px 20px 20px;
  text-align: center;
  margin:0 auto;
}

.video-single-room-msg-container {
  transform:translateY(var(--single-room-y));
  opacity:0;
  transition-timing-function: cubic-bezier(.41, .28, .71, .13);
  transition:transform 0.1s, opacity 0.1s;
  will-change: transform, opacity;
}

.video-all-room-msg-container {
  transform:translateY(var(--all-room-y));
  opacity:0;
  transition-timing-function: cubic-bezier(.41, .28, .71, .13);
  transition:transform 0.1s, opacity 0.1s;
  will-change: transform, opacity;
}


.video-single-room-msg-title, .video-all-room-msg-title {
  margin-bottom:20px;
  font-weight: 600;
  font-size:1.95vw;
}

.video-single-room-msg-overview, .video-all-room-msg-overview {
  font-size: 16px;
  line-height: 28px;
  font-weight: normal;
}
*/

/*additional spacing a background color for slide show component*/
.additional-height {
  width:100%;
  height:40px;
  position:relative;
  background-color:#e4ebf1;
}


/*overlay for google store options sub menus*/
.overlay {
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  height:100vh;
  opacity:.85;
  z-index:90;
  background-color:#050505;
}

.show {
  display:block;
}

.hide {
  display:none;
}

/*
.video-single-room-msg-container {
  opacity:var(--single-room-opacity);
}
*/

/*
.video-all-room-msg-container {
  opacity:var(--all-room-opacity);
}
*/