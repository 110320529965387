.nest-wifi-google-store-nav-wrapper {
    position:relative;
    padding:0 20px;
    /*width:100%;*/
    max-width:1440px;
    margin:auto;
    height:56px;
    display:flex;
    justify-content: space-between;
    z-index: 93;
    background-color:#fff;
  }
  
  .nest-wifi-google-store-nav-options-container {
    display: flex;
    /*border:1px solid green;*/
    justify-content: center;
    align-items: center;
  }
  
  .nest-wifi-google-store-logo-container{
    width:134px;
    height:24px;
    display: inline-block;
    cursor: pointer;
    font-size: 22px;
    color: #423f3f;
    /*border:1px solid orange;*/
  }

  .close-mobile-btn {
    display:none;
  }
  
  .nest-wifi-google-store-option-link {
    text-decoration: none;
    cursor: pointer;
  }
  
  .nest-wifi-google-store-option-link-container:nth-child(n + 2){
    margin-left:20px;
  }
  
  .nest-wifi-google-store-option {
    color:#3c4043;
    line-height:20px;
    font-size:13px;
    font-weight:500;
    padding-top:5px;
    background-color:transparent;
    border:none;
    cursor: pointer;
  }
  
  .nest-wifi-google-store-option:hover, .nest-wifi-google-store-option:focus {
    color:#1967d2;
  }
  
  .nest-wifi-google-store-option-link-container:nth-child(-n + 5) .nest-wifi-google-store-option::after {
    content: "";
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M18.59 16.41L20 15l-8-8-8 8 1.41 1.41L12 9.83'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cg mask='url(%23b)' fill='%235f6368'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    transform:rotate(180deg);
    display:inline-block;
    width:12px;
    height:12px;
    margin-left:4px;
    vertical-align: middle;
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center center;
    transition:transform .35s ease;
  }
  
  .nest-wifi-google-store-option-link-container:nth-child(-n + 5) .nest-wifi-google-store-option:hover::after,
  .nest-wifi-google-store-option-link-container:nth-child(-n + 5) .nest-wifi-google-store-option:focus::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M18.59 16.41L20 15l-8-8-8 8 1.41 1.41L12 9.83'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cg mask='url(%23b)' fill='%231967d2'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
  
  .nest-wifi-google-store-option-link-container:nth-child(-n + 5) .up-arrow::after {
    transform:rotate(0deg);
  }
  
  
  .nest-wifi-google-store-option-sub-menu-container {
    position:absolute;
    left:calc(50% - 50%);
    /*display:none;*/
    padding-top:32px;
    width:100%;
    /*border:1px solid green;*/
    z-index: 1;
    background-color:#fff;
    cursor:initial;
  }
  
  .inactive-option {
    display:none;
    top:-1000px;
  }
  
  .active-option {
    display:block;
    top:56px;
  }
  
  .nest-wifi-google-store-option-sub-menu {
    display:flex;
    width:100%;
    /*border:2px solid red;*/
    min-height:448px;
    z-index:1;
    padding-left:50px;
    padding-right:20px;
    background-color:#fff;
  }
  
  .nest-wifi-goolge-store-option-sub-menu-overview-container {
    display:flex;
    width:64%;
    margin-right:32px;
  }
  
  .nest-wifi-google-store-option-sub-menu-overview {
    display:flex;
    flex-direction: column;
    width:100%;
  }
  
  .nest-wifi-google-store-option-sub-menu-overview-heading-container {
    display:flex;
    width:100%;
    justify-content: space-between;
    flex-wrap:wrap;
    align-items: center;
    /*border:1px solid blue;*/
    margin-bottom:32px;
  }
  
  .nest-wifi-google-store-option-sub-menu-overview-heading-wrapper {
    display:flex;
    flex-direction: column;
    padding:8px 0;
    margin:auto 0;
  }
  
  .nest-wifi-google-store-option-sub-menu-overview-heading {
    font-size:18px;
    line-height:36px;
    font-weight: 500;
  }

.nest-wifi-google-store-option-sub-menu-overview-heading-link {
    text-decoration: none;
    color:#3c4043;
  }
  
  .nest-wifi-google-store-option-sub-menu-overview-heading-btn-link {
    text-decoration: none;
  }
  
  .nest-wifi-google-store-option-sub-menu-overview-heading-btn {
    display:flex;
    height:36px;
    padding:0 20px;
    align-items: center;
    border-radius:4px;
    border:2px solid #dadce0;
    background-color:transparent;
    cursor: pointer;
  }
  
  .nest-wifi-google-store-option-sub-menu-overview-heading-btn:hover {
    border:2px solid #1967d2;
    color:#1967d2;
  } 
  
  .nest-wifi-google-store-sub-menu-overview-heading-spacer {
    width:100%;
    height:1px;
    margin-top:16px;
    background-color:#dadce0;
  }
  
  .nest-wifi-google-store-sub-menu-overview-column-container {
    display:grid;
    grid-template-columns: auto auto auto;
    grid-gap: 0 32px;
    width:100%;
  }
  
  .nest-wifi-google-store-sub-menu-overview-column {
    padding-bottom:32px;
  }
  
  .nest-wifi-google-store-sub-menu-overview-column:nth-child(2) .nest-wifi-google-store-sub-menu-overview-sub-column:nth-child(n + 2) {
    margin-top:24px;
  }
  
  .nest-wifi-google-store-sub-menu-overview-sub-column {
    padding:0;
    margin-bottom:48px;
    list-style-type: none;
  }
  
  .nest-wifi-google-store-sub-menu-overview-sub-column-heading {
    font-size:11px;
    margin-bottom:24px;
    color:#3c4043;
  }
  
  .nest-wifi-google-store-sub-menu-overview-sub-column-item {
    font-size:13px;
    margin-bottom:24px;
    color:#3c4043;
  }
  
  .nest-wifi-google-store-sub-menu-overview-sub-column-item:hover {
    cursor: pointer;
    color:#1967d2;
  }
  
  .sub-column-item-status {
    display:inline-block;
    text-transform: uppercase;
    margin-left:8px;
    font-size: 11px;
    line-height: 16px;
    font-weight: 700;
    color:#1967d2;
  }
  
  .nest-wifi-google-store-options-sub-menu-other-options-container {
    padding:75px 48px 32px;
    background-color:#f8f9fa;
    flex-grow:1;
  }
  
  .nest-wifi-google-store-options-sub-menu-other-options {
    padding:0;
    margin:0;
    list-style-type: none;
  }
  
  .nest-wifi-google-store-options-sub-menu-other-option {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom:16px;
    color:#3c4043;
  }
  
  .nest-wifi-google-store-options-sub-menu-other-option:hover {
    cursor: pointer;
    color:#1967d2;  
  }
  
  
  .nest-wifi-google-store-nav-other-options-container {
    display:flex;
    /*border:1px solid red;*/
    align-items: center;
    justify-content: center;
  }
  
  .nest-wifi-google-store-other-option-link {
    cursor: pointer;
    display: inline-block;
    margin-left:24px;
    /*border:1px solid green;*/
    text-decoration: none;
  }

  .nest-wifi-google-store-other-option-link:nth-child(2){
    display:none;
  }
  
  .nest-wifi-google-store-other-option {
    width:24px;
    height:24px;
    cursor: pointer;
    color:#5f6368;
    fill: #5f6368;
  }
  
  .nest-wifi-google-store-email-btn-container {
    margin-left:24px;
  }
  
  .google-email-btn {
    border:none;
    padding:0;
    cursor: pointer;
    background-color:#fff;
    margin-top:1px;
    display:flex;
    align-items: center;
    justify-content: center;
  }
  
  .google-email-img {
    border-radius: 50%;
  }

  @media screen and (min-width:600px){
    .nest-wifi-google-store-logo-container-mobile {
      display: none;
    }
  }

  @media  screen and (max-width:599px) {
    .nest-wifi-google-store-logo-container {
      display:none;
    }

    .nest-wifi-google-store-logo-container-mobile {
      width:24px;
      height:24px;
      font-size: 22px;
      color: #423f3f;
    }
  }

  @media screen and (min-width:1015px){
    .nest-wifi-google-store-other-option-link:nth-child(2) {
      display: inline-block;      
    }
  }


  @media screen and (max-width:1023px) {
    .nest-wifi-goolge-store-mobile-utility-container {
      text-align: center;
      width:100%;
      padding:46px 16px;
      display:flex;
      justify-content: center;
      align-items: center;
      background-color: #f8f9fa;
    }

    .nest-wifi-goolge-store-mobile-utility-container-help-link {
      height:48px;
      color:#3c4043;
      display:inline-flex;
      align-items: center;
      text-decoration: none;
    }

    .nest-wifi-goolge-store-mobile-utility-container-help-icon {
      width:24px;
      height:24px;
      display:inline-block;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%235f6368' d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z'%3E%3C/path%3E%3C/svg%3E");
      margin-right:5px;
    }

    .nest-wifi-google-store-options-container {
      display:none;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-btn {
      width:48px;
      height:48px;
      margin-right:15px;
      /*border:1px solid blue;*/
      display: flex;
      justify-content: center;
      align-items: center;
      border:none;
      background-color: transparent;
      cursor: pointer;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list {
      position: absolute;
      left:0;
      top:55px;
      /*original width before scroller adjustment*/
      /*width:0;*/
      width:100vw;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-container {
      /*height:613px;*/
      display:block;
      position: absolute;
      top:0;
      left:0;
      width:100vw;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-categories {
      padding-top:16px;
      padding-left:16px;
      padding-right:16px;
      background-color: #fff;
      display: flex;
      flex-flow:wrap;
    }



    .nest-wifi-goolge-store-mobile-options-nav-list-category-container {
      display:flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;
      width:100%;
      margin:auto;
      max-width: 560px;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-container-btn-wrapper {
      max-width: 248px;
      width: 47.5%;
      display:block;
      margin-bottom: 32px;
      text-align: center;
      font-size:18px;
      line-height: 24px;
      font-weight: 400;
    }
    
    .nest-wifi-goolge-store-mobile-options-nav-list-category,
    .nest-wifi-goolge-store-mobile-options-nav-list-subcategory {
      background-color: #f8f9fa;
      position: absolute;
      top:0;
      left:0;
      width:100%;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-subcategory {
      background-color: #fff;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-container-btn,
    .nest-wifi-goolge-store-mobile-options-nav-list-category-container-link {
      width:100%;
      height:100%;
      border:none;
      background-color: #fff

    }

    .google-store-option-img {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      margin-bottom: 17px;
      width:100%;
      height:0;
      padding-top:53.9%;
      display:block;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-heading-container,
    .nest-wifi-goolge-store-mobile-options-nav-list-subcategory-heading-container {
      position: relative;
      font-size: 22px;
      font-weight:500;
      line-height: 28px;
      text-align: center;
      border-bottom:1px solid #dadce0;
      color:#3c4043;
      background-color: #fff;
      padding:19px 60px;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-heading,
    .nest-wifi-goolge-store-mobile-options-nav-list-subcategory-heading {
      font-size:22px;
      font-weight: 500;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-heading-link {
      text-decoration: none;
      color:#3c4043;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-scroller-subcategory-links,
    .nest-wifi-goolge-store-mobile-options-nav-list-category-scroller-subcategory-link,
    .nest-wifi-goolge-store-mobile-options-nav-list-subcategory-product-link {
      max-width: 592px;
      margin:auto;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-scroller-subcategory-btn,
    .nest-wifi-goolge-store-mobile-options-nav-list-category-scroller-subcategory-link,
    .nest-wifi-goolge-store-mobile-options-nav-list-subcategory-product-link {
      width:100%;
      margin:auto;
      padding:16px 24px;
      display:flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      color:#3c4043;
      background-color: #fff;
      border:0;
      text-align: left;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-scroller-subcategory-link,
    .nest-wifi-goolge-store-mobile-options-nav-list-subcategory-product-link {
      text-decoration: none;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-scroller-subcategory-title {
      flex-grow: 1;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-editorial-links {
      margin-top:40px;
      max-width: 592px;
      padding-bottom:8px;
      margin-left:auto;
      margin-right:auto;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-editorial-link {
      display:block;
      color:#3c4043;
      width:100%;
      padding:0 24px;
      margin-bottom:32px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      text-decoration: none;
      text-align: left;
    }

    .nest-wifi-google-store-mobile-options-icon-open-wrapper {
      height:24px;
      width:24px;
    }

    .nest-wifi-google-store-mobile-options-icon-close-wrapper {
      width:24px;
    }

    .nest-wifi-google-store-mobile-options-chevron {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M18.59 16.41L20 15l-8-8-8 8 1.41 1.41L12 9.83'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cg mask='url(%23b)' fill='%235f6368'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");;
      background-repeat: no-repeat;
      background-position:center center;
      background-color: transparent;
      border:none;
      width:24px;
      height:24px;
      transform: rotate(90deg);
    }

    .nest-wifi-google-store-mobile-options-chevron-left {
      position: absolute;
      left:12px;
      top:0;
      bottom:0;
      margin:auto;
      border:none;
      height:48px;
      width:48px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M18.59 16.41L20 15l-8-8-8 8 1.41 1.41L12 9.83'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cg mask='url(%23b)' fill='%235f6368'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position:center center;
      background-color: transparent;
      transform:rotate(-90deg);
      cursor: pointer;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-scroller, 
    .nest-wifi-google-store-mobile-options-subcategory-scroller, 
    .nest-wifi-google-store-mobile-options-subcategory-product-scroller {
      overflow-y: scroll;
      overscroll-behavior: contain;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-container {
      z-index: 100;
      background-color: #f8f9fa;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-container-btn {
      cursor: pointer;
    }

    .google-store-option-text {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color:#3c4043;
      font-weight:400;
    }

  }

  @media screen and (max-width:1023px) and (min-width:600px) {
    .nest-wifi-goolge-store-mobile-options-nav-list-categories {
      padding-top:64px;
      padding-bottom:19px;
    }

    /*
    .nest-wifi-goolge-store-mobile-utility-container {
      padding-top:100px;
      padding-bottom:100px; 
    }
    */

    .nest-wifi-goolge-store-mobile-utility-container-help-link {
      font-size: 22px;
      line-height:28px;
      font-weight: 500;
      text-decoration: none;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-container-btn-wrapper {
      width:44.2%;
      max-width:248px;
      margin-bottom:40px;
      font-size: 22px;
      line-height: 28px;
      text-align: center;
      font-weight: 400;
    }
    .nest-wifi-goolge-store-mobile-options-nav-list-category-container-btn {
      width:100%;
      height:100%;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-heading-container,
    .nest-wifi-goolge-store-mobile-options-nav-list-subcategory-heading-container {
      font-size:28px;
      line-height:36px;
      padding:36px 16px;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-heading,
    .nest-wifi-goolge-store-mobile-options-nav-list-subcategory-heading {
      font-size:28px;
      line-height:36px;
      color:#3c4043;
    }

    .nest-wifi-goolge-store-mobile-options-subcategory-whitespace,
    .nest-wifi-goolge-store-mobile-options-nav-list-subcategory-scroller-container {
      background-color: #fff;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-scroller-subcategory-link-container {
      padding:32px 0;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-scroller-subcategory-btn,
    .nest-wifi-goolge-store-mobile-options-nav-list-category-scroller-subcategory-link,
    .nest-wifi-goolge-store-mobile-options-nav-list-subcategory-product-link {
      padding:32px 24px;
      font-size: 22px;
      line-height:28px;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-scroller-subcategory-btn {
      border:none;
      cursor: pointer;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-editorial-links {
      margin-top: 48px;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-category-editorial-link {
      font-size:22px;
      line-height:28px;
      font-weight:500;
      text-decoration: none;
      text-align: left;
    }

    .nest-wifi-google-store-mobile-options-chevron {
      font-size: 22px;
      line-height:28px;
    }

    .google-store-option-text {
      font-size: 22px;
      line-height: 28px;
      text-align: center;
    }
  }

  @media screen and (min-width:1024px) {
    .nest-wifi-google-store-sub-menu-overview-sub-column-heading {
      margin-bottom:16px;
      font-size:12px;
      line-height: 16px;
      font-weight: 700;
      color:#3c4043;
      text-transform: uppercase;
      cursor: pointer;
    }

    .nest-wifi-google-store-sub-menu-overview-sub-column-item {
      margin-bottom: 16px;
      font-size:14px;
      line-height: 20px;
      font-weight: 400;
      color:#3c4043;
      cursor: pointer;
    }

    .nest-wifi-google-store-options-sub-menu-other-option {
      font-size: 16px;
      margin-bottom: 16px;
      font-weight: 500;
      line-height: 24px;
      color:#3c4043;
      cursor: pointer;
    }

    .nest-wifi-google-store-options-container {
      display:flex;
      /*border:1px solid purple;*/
      margin-left:24px;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list {
      display:none;
    }

    .nest-wifi-goolge-store-mobile-options-nav-list-btn {
      display: none;
    }

  }

  .adj-dropdown-nav {
    overflow: hidden;
  }


  .hide-google-nav-element {
    display:none;
  }

