.video-devices-colors-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    /*border:1px solid blue;*/
  }
  
  .video-devices-colors-msg-container-wrapper {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:10vw 0 0;
    padding:0 12px;
    text-align: center;
    width:100%;
  }
  
  .video-devices-colors-msg-container {
    margin-bottom:10px;
    width:100%;
    font-weight: 600;
    opacity:0;
    transform:translateY(30%);
  }

  .video-devices-colors-video-container {
    position:relative;
    display: grid;
    width:100%;
    height:100%;
    margin-top:20px;
    max-width:100%;
  }

  .video-devices-colors-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .video-devices-colors-btn-container {
    /*border:1px solid red;*/
    width:100%;
    opacity:0;
    transform:translateY(30%);
  }
  
  .slide-up-video-devices-colors-msg-container {
    transform:translateY(0);
    opacity:1;
    transition:transform .6s .15s cubic-bezier(0.4, 0, 0.2, 1), opacity .6s .15s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .slide-up-video-devices-colors-btn-container {
    transform:translateY(0);
    opacity:1;
    transition:transform .6s .3s cubic-bezier(0.4, 0, 0.2, 1), opacity .6s .3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .video-devices-colors-btn-link {
    display:inline-flex;
    position:relative;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 3px;
    border:2px solid #1967D2;
    padding:6px 12px;
    background-color:transparent;
    font-weight: 500;
    line-height: 1.95312vw;
    font-size:1.36719vw;
  }
  
  .video-devices-colors-btn-link::before {
    content:"";
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    background-color:#1967D2;
    border-radius: 3px;
    transform:scale(.9, .65);
    opacity:0;
  }
  
  .video-devices-colors-btn-link:hover {
    border:2px solid transparent;
  }
  
  .video-devices-colors-btn-link:hover::before {
    content:"";
    position: absolute;
    transform-origin: center center;
    top:-4px;
    left:-2px;
    right:-2px;
    bottom:-4px;
    opacity: 1;
    background-color:#1967D2;
    transform:scale(1, .9);
    /*transition:transform .25s cubic-bezier(0.1, 0.1, 0, 1), opacity .25s cubic-bezier(0.1, 0.1, 0, 1);*/
    transition:all .25s ease;
  }
  
  .video-devices-colors-btn-link:hover .video-devices-colors-btn {
    color:#fff;
    background-color:#1967D2;
  }
  
  .video-devices-colors-btn {
    color:#1967D2;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color:transparent;
    opacity:1;
    z-index: 1;
  }

  @media screen and (max-width:767px){
    .video-devices-colors-container {
      line-height: 25px;
      font-size: 15px;
    }

    .video-devices-colors-msg-container {
      line-height: 32px;
      font-size:24px;
    }

    .video-devices-colors-msg-container-wrapper {
      padding:30px 12px;
    }

    .video-devices-colors-btn-link {
      line-height:20px;
      font-size:14px;
    }

  }

  @media screen and (min-width:768px){
    .video-devices-colors-container {
      padding-top:50px;
      line-height: 2.4414vw;
      font-size: 1.4648vw;
    }

    .video-devices-colors-msg-container-wrapper {
      margin:2vw 0 0;
    }

    .video-devices-colors-msg-container {
      font-size:40px;
      line-height:54px;
      margin-bottom:20px;
    }

    .video-devices-colors-btn-link {
      line-height: 1.9531vw;
      font-size:1.3671vw;
    }

    .video-devices-colors-video-container {
      margin-top:40px;
      width:80%;
    }

  }
  