.wifi-slide-show-wrapper {
    position: relative;
}

.wifi-slide-show-heading-wrapper {
    background-color:#F8F9FA;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
    position: relative;
    text-align: center;
    width:100%;
    z-index:11;
}

.wifi-slide-show-heading-container {
    max-width: 912px;
    font-weight: 600;
    text-align: center;
}

.wifi-slide-show-heading {
    display: inline-block;
}


.wifi-slide-show-imgs-wrapper {
    position:relative;
    background:linear-gradient(to bottom, #fff8eb 0%, #fff8eb 25%, #f5ded2 50%, #f9ebe4 75%, #E4EBF1 90%);
}

.wifi-slide-show-imgs-container {
    height:300vh;
}

.box {
    position: absolute;
    left: 440px;
    top: 0px;
    width: 50px;
    height: 1261.25px;
    border: 1px solid red;
    z-index: 11;
    display: none;
}

.wifi-slide-show-imgs-gradient {
    position:absolute;
    top:-2vh;
    left:0;
    width:100%;
    height:15vh;
    /*original version*/
    /*background:linear-gradient(to bottom, #F8F9FA 25%, rgba(255,255,255,0) 70%);*/
    background:linear-gradient(to bottom, #F8F9FA 0%, rgba(255,255,255,0) 100%);
    z-index: 10;
}

.wifi-slide-show-canvas-container {
    position: sticky;
    width:100%;
    height:100vh;
    /*top: translateY(var(--canvas-y));*/
    /*border:1px solid green;*/
}

.wifi-slide-show-imgs-heading-wrapper {
    bottom:0;
    display:flex;
    justify-content: center;
    margin-top:-100vh;
    min-height:100vh;
    position:sticky;
    width:100%;
    z-index:11;
    /*visibility: hidden;*/
    /*overflow:hidden;*/
}

.wifi-slide-show-imgs-heading-container {
    position:absolute;
    display:grid;
    /*border:1px solid orange;*/
}

.wifi-slide-show-imgs-heading-text-container, .wifi-slide-show-imgs-heading-text-container {
    text-align: center;
    width:100%;
}


.wifi-slide-show-imgs-heading-title-container {
    opacity:0;
    transform:translateY(30px);
    transition:opacity .3s cubic-bezier(0.4, 0.2, 0, 1), transform .3s cubic-bezier(0.4, 0.2, 0, 1);
    /*line-height: 32px;
    font-size: 24px;*/
    font-weight: 600;
    margin-bottom: 20px;
}

.adjust-heading-title-container {
    opacity:1;
    transform:translateY(0px);
    transition:opacity .6s cubic-bezier(0.4, 0.2, 0, 1), transform .6s cubic-bezier(0.4, 0.2, 0, 1);
}

.wifi-slide-show-imgs-heading-title, .wifi-slide-show-imgs-heading-caption {
    display: inline-block;
}

.wifi-slide-show-imgs-heading-caption-container {
    opacity:0;
    transform:translateY(30px);
    transition:opacity .3s cubic-bezier(0.4, 0.2, 0, 1), transform .3s cubic-bezier(0.4, 0.2, 0, 1);
    /*line-height: 24px;
    font-size:16px;*/
    margin:0 auto;
    max-width:400px;
    padding:0 20px;
}

.adjust-heading-caption-container {
    opacity:1;
    transform:translateY(0px);
    transition:opacity .6s cubic-bezier(0.4, 0.2, 0, 1), transform .6s cubic-bezier(0.4, 0.2, 0, 1);
}

.sup {
    line-height: 0;
    top:0;
    vertical-align: super;
    position:relative;
}

.google-assist-img-link-container {
    margin-top:10px;
    opacity:0;
    transition:opacity .3s cubic-bezier(0.4, 0.2, 0, 1), transform .3s cubic-bezier(0.4, 0.2, 0, 1);
    transform:translateY(30px);
}

.adjust-google-assist-img-container {
    opacity:1;
    transform:translateY(0px);
    transition:opacity .6s cubic-bezier(0.4, 0.2, 0, 1), transform .6s cubic-bezier(0.4, 0.2, 0, 1);
}

.wifi-slide-show-imgs-mobile-wrapper {
    background-color: #e4ebf1;
    margin-top: -60px;
    padding-bottom: 40px;
    text-align: center;
}

.wifi-slide-show-imgs-mobile-shell {
    margin: 0 auto 40px;
    max-width: 550px;
    width: 100%;
}

.wifi-slide-show-imgs-mobile-container {
    background-image: url('https://server.kando-proto-2.com/mobile-point-img.png');
    display: grid;
    position:relative;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center center;
    transition:opacity .3s ease-in;
}

.wifi-slide-show-imgs-mobile-container svg {
    opacity:0;
    visibility: hidden;
}

.wifi-slide-show-imgs-mobile-heading-container {
    font-weight:600;
    margin-bottom: 20px;
}

.wifi-slide-show-imgs-mobile-heading {
    display: inline-block;
}

.wifi-slide-show-imgs-mobile-heading-description-wrapper {
    margin: 0 auto;
    max-width: 400px;
    padding-left: 20px;
    padding-right: 20px;
}

.wifi-slide-show-imgs-mobile-heading-description-container {
    display: inline-block;
}

.wifi-slide-show-imgs-mobile-heading-description-container sup{
    position: relative;
    font-size: 75%;
    line-height: 0;
    top: 0;
    vertical-align: super;
}

.wifi-slide-show-imgs-mobile-goggle-assist-img-container {
    margin-top:10px;

}

.wifi-slide-show-imgs-mobile-goggle-assist-img-container img {
    opacity:1;
    max-width:100%;
    position:relative;
    top:0;
    left:0;
    background-size:cover;
    background-position: center center;
    background-repeat: no-repeat;
    transition: opacity .3s ease-in;
    width:170px;
    height:auto;
}


@media screen and (min-width:768px) {
    .wifi-slide-show-heading-container{
        line-height: 3.125vw;
        font-size: 2.34375vw;
    }

    .wifi-slide-show-imgs-mobile-container {
        will-change: opacity, transform;
    }

    .wifi-slide-show-imgs-mobile-heading-container {
        line-height:2.5390625vw;
        font-size:1.953125vw;
    }

    .wifi-slide-show-imgs-mobile-heading {
        line-height: 2.5390625vw;
        font-size: 1.953125vw;
    }

    .wifi-slide-show-imgs-heading-title-container {
        line-height: 2.5390625vw;
        font-size: 1.953125vw;
    }

    .wifi-slide-show-imgs-heading-caption-container {
        line-height: 2.1484375vw;
        font-size: 1.3671875vw;
    }

    .wifi-slide-show-imgs-mobile-heading-description-wrapper {
        line-height: 2.1484375vw;
        font-size:1.3671875vw;
    }

    .wifi-slide-show-imgs-mobile-goggle-assist-img-container img {
        will-change: opacity, tansform;
    }
    
}


@media screen and (max-width:1023px) {
    .wifi-slide-show-heading-wrapper {
        background: linear-gradient(to bottom, #F8F9FA 0%, #F8F9FA 50%, rgba(255, 255, 255, 0) 100%);
        padding-top: 60px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 250px;
    }

    .wifi-slide-show-heading-container {
        padding:0 12px;
    }

    .wifi-slide-show-imgs-wrapper {
        background: #e5ebf2;
    }

    .wifi-slide-show-imgs-gradient {
        display:none;
    }

    .wifi-slide-show-imgs-heading-container {
        grid-template-columns: 1fr;
        padding:0 20px;
        top:65%;
        width:100%;
    }
    
    .wifi-slide-show-imgs-heading-text-container:nth-child(2){
        display:none;
    }

}


@media  screen and (max-width:767px) {
    .wifi-slide-show-heading-container{
        line-height: 32px;
        font-size: 24px;
    }

    .wifi-slide-show-imgs-heading-title-container {
        line-height: 26px;
        font-size: 20px;
    }

    .wifi-slide-show-imgs-heading-caption-container {
        line-height: 22px;
        font-size: 14px;
    }

    .wifi-slide-show-imgs-mobile-heading-container {
        line-height: 26px;
        font-size: 20px;
    }

    .wifi-slide-show-imgs-mobile-heading-description-wrapper {
        line-height: 22px;
        font-size: 14px;
    }
    
}

@media screen and (max-width:324px) {
    .wifi-slide-show-heading-wrapper {
        padding-top: 0px;
    }
}

@media screen and (min-width:1024px) {
    .wifi-slide-show-heading-container{
        max-width: 890px;
    }

    .wifi-slide-show-imgs-heading-wrapper {
        visibility: visible;
    }

    .wifi-slide-show-imgs-heading-container {
        grid-template-columns: 1fr 1fr;
        top:calc(50% + 16vh);
        /*border:2px solid lime;*/
        width:81.5%;
    }

    .wifi-slide-show-canvas-container {
        /*transform: translateY(var(--canvas-y));*/
        will-change: transform;
    }
    
    .wifi-slide-show-imgs-mobile-wrapper {
        display:none;
    }
}

@media screen and (min-width:1230px) {
    .wifi-slide-show-heading-container {
        line-height: 50px;
        font-size: 38px;
        font-weight: 600;
    }

    .wifi-slide-show-imgs-heading-title-container {
        line-height: 32px;
        font-size: 24px;
    }

    .wifi-slide-show-imgs-heading-caption-container {
        line-height: 24px;
        font-size: 16px;
    }
}