.video-parent-wrapper {
    position: relative;
    width:100%;
    height:400vh;
    /*border:2px solid lightgreen;*/
    /*original version*/
    /*background-color: lightpink;*/
    background-color: #F8F8F8;
    /*margin-top:200px;
    padding-top:55px;*/
  }
  
  .video-child-wrapper {
    position:sticky;
    top:0;
    left:0;
    width:100%;
    /*original height*/
    /*height:1012px;*/
    height:100vh;
    background-color:#F8F8F8;
    /*background-color:orange;*/
    transform:translateY(var(--video-room-intro-shift-y));
    /*transition-timing-function: cubic-bezier(0,1,0,1);*/
    /*remove comments after testing
    transition-property: transform;
    transition-duration: var(--td-shift-y);
    transition-timing-function: var(--tf-shift-y);
    */
    /*transition: transform .3s;*/
    will-change: transform;
    /*border:2px solid red;*/
    overflow: hidden;
    /*opacity:0.3*/
  }
  
  .video-rooms-container {
    position: absolute;
    top:0;
    left:0;
    /*original height*/
    /*height:1012px;*/
    height:100vh;
    width:100%;
    clip-path:inset(var(--single-video-clip-top) var(--single-video-clip-x) var(--single-video-clip-bottom) var(--single-video-clip-x));
    /*transition-timing-function: cubic-bezier(0.075, 0.82, 1, 0.5);*/
    /*transition-timing-function: cubic-bezier(1, .1, 0.96, .61);*/
    /*transition: clip-path .3s ease-in;*/
    /*remove comments after testing
    transition: clip-path var(--td-shift-y) var(--cp-shift);
    */
    will-change: clip-path;
  }
  
  .video-single-room-container {
    /*original height*/
    /*height:949px;*/
    height:100vh;
    position: absolute;
    width:100%;
    top:0;
    left:0;
    transform:translateY(var(--single-video-y)) scale(var(--single-video-scale));
    transform-origin: 50% var(--single-video-origin);
    opacity:var(--single-video-opacity);
    /*transition-timing-function: cubic-bezier(.9, .08, 1, .41);*/
    /*transition-timing-function: cubic-bezier(0,1,0,1);*/
    /*transition: transform .3s linear, transform-origin .3s linear, opacity .3s linear;*/
    /*remove comments after testing
    transition: transform var(--td-shift-y) var(--tf-shift-y), transform-origin var(--td-shift-y) var(--tf-shift-y), opacity var(--td-shift-y) var(--tf-shift-y);
    */
    
    will-change: transform, transform-origin, opacity;
  }

  .video-single-room-desktop-container, .video-single-room-mobile-container {
    display: grid;
    position: relative;
    width:100%;
    height:100%;
    overflow: hidden;
  }
  
  .video-all-room-container {
    /*original height*/
    /*height:949px;*/
    height:100vh;
    position: absolute;
    width:100%;
    top:0;
    left:0;
    /*original version*/
    /*transform:translateY(68.75px) scale(1);*/
    transform:translateY(var(--single-video-y)) scale(1);
    /*original version*/
    /*transform-origin: 50% 50%;*/
    transform-origin: 50% var(--single-video-origin);
    opacity:var(--all-video-opacity);
    /*transition-timing-function: cubic-bezier(.9, .08, 1, .41);*/
    /*transition-timing-function: cubic-bezier(0,1,0,1);*/
    /*transition:transform .3s linear, transform-origin .3s linear, opacity .3s linear;*/
    /*remove comments after testing
    transition: transform var(--td-shift-y) var(--tf-shift-y), transform-origin var(--td-shift-y) var(--tf-shift-y), opacity var(--td-shift-y) var(--tf-shift-y);
    */
    
    will-change: transform, transform-origin, opacity;
  }

  .video-all-room-desktop-container, .video-all-room-mobile-container {
    display: grid;
    position:relative;
    width:100%;
    height:100%;
    overflow:hidden;
  }
  
  .video-room-shape {
    position:absolute;
    top:0;
    left:0;
    object-fit: cover;
    width:100%;
    height:100%;
    object-position: top;
  }
  
  .video-room-header-container {
    /*border:1px solid red;*/
    position:absolute;
    padding:60px 60px 40px;
    text-align: center;
    width:100%;
    left:0;
    top:70px;
    transform:translateY(var(--video-room-header-y));
    /*transition-timing-function: cubic-bezier(.41, .28, .71, .13);*/
    /*transition-timing-function: cubic-bezier(0.075, 0.82, 1, 0.5);*/
    transition:transform var(--td-shift-y) var(--cp-shift);
    will-change: transform;
    /*opacity: 1;*/
  }
  
  .video-room-header-text {
    /*font-size: 2.3vw;*/
    font-weight: 600;
    margin:0 0 20px;
    color:#3c4043;
  }
  
  .video-room-msg-wrapper {
    position:absolute;
    /*original value*/
    /*top:95px;*/
    top:70px;
    width:100%;
    left:0;
  }
  
  .video-room-spacer {
    position:relative;
    max-width:600px;
    margin:0 auto;
  }
  
  .video-single-room-msg-container, .video-all-room-msg-container {
    position: absolute;
    top:0;
    width:100%;
    padding:80px 20px 20px 20px;
    text-align: center;
    margin:0 auto;
  }
  
  .video-single-room-msg-container {
    transform:translateY(var(--single-room-y));
    opacity:var(--single-room-opacity);
    /*opacity:0;*/
    /*transition-timing-function: cubic-bezier(.41, .28, .71, .13);*/
    /*remove comments after testing
    transition-timing-function: ease;
    transition-duration: 0.3s;
    transition:transform, opacity;
    */
    /*transition-timing-function: cubic-bezier(0.075, 0.82, 1, 0.5);*/
    will-change: transform, opacity;
  }
  
  .video-all-room-msg-container {
    /*border:1px solid red;*/
    transform:translateY(var(--all-room-y));
    opacity:var(--all-room-opacity);
    /*opacity:0;*/
    /*transition-timing-function: cubic-bezier(.41, .28, .71, .13);*/
    /*remove comments after testing
    transition-timing-function: ease;
    transition-duration: 0.3s;
    transition:transform, opacity;
    */
    /*transition-timing-function: cubic-bezier(0.075, 0.82, 1, 0.5);*/
    will-change: transform, opacity;
  }
  
  
  .video-single-room-msg-title, .video-all-room-msg-title {
    margin-bottom:20px;
    font-weight: 600;
    /*font-size:1.95vw;*/
  }
  
  .video-single-room-msg-overview, .video-all-room-msg-overview {
    font-size: 16px;
    /*original value*/
    /*line-height: 24px;*/
    line-height: 21px;
    letter-spacing: 0px;
  }

  @media screen and (max-width:767px){
    .video-single-room-container, .video-all-room-container {
      transform-origin: 30% var(--single-video-origin);
    }

    .video-single-room-desktop-container {
      display:none;
    }

    .video-all-room-desktop-container {
      display:none;
    }

    .video-room-shape {
      object-position: bottom;
      object-fit: contain;
    }

    .video-room-header-text {
      line-height: 32px;
      font-size:24px;
    }

    .video-single-room-msg-container, .video-all-room-msg-container {
      padding-top:30px;
    }

    .video-single-room-msg-title, .video-all-room-msg-title {
      line-height:26px;
      font-size:20px;
    }
    
  }

  @media screen and (max-width:599px) {
    .video-single-room-msg-overview, .video-all-room-msg-overview {
      max-width: calc(272px + 88 * (100vw - 360px) / 239);
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media screen and (min-width:768px) {
    .video-parent-wrapper{
      min-height:1200px;
    }

    .video-single-room-mobile-container {
      display:none;
    }

    .video-all-room-mobile-container {
      display:none;
    }

    .video-room-header-container {
      line-height:2.44140625vw;
      font-size:1.46484375vw;
    }

    .video-room-header-text {
      line-height: 3.125vw;
      font-size:2.34375vw;
    }

    .video-single-room-msg-title, .video-all-room-msg-title {
      line-height: 2.5390625vw;
      font-size:1.9535125vw;
    }
  }

  @media screen and (min-width:1024px) {
    .video-single-room-msg-overview, .video-all-room-msg-overview {
      max-width:524px;
      margin-left:auto;
      margin-right:auto;
    }
  }

  @media screen and (min-width:1230px){
    .video-room-header-text {
      line-height: 50px;
      font-size: 38px;
    }

    .video-single-room-msg-title, .video-all-room-msg-title {
      line-height: 32px;
      font-size:24px;
    }
  }
