.sticky-style-wrapper {
    background-color:#F8F9FA;
    /*border-top:1px solid red;*/
}

.sws-sticky-parent {
    position:relative;
    /*height:200vh;*/
    /*width:100%;*/
    /*background-color:red;*/
    /*padding-bottom: 100px;*/
    /*border-top:1px solid orange;*/
}

/*test element*/
.ruler {
    position:absolute;
    left:0;
    top:452px;
    height:949px;
    width:50px;
    border:1px solid red;
    display:none;
}

.ruler2 {
    position:absolute;
    left:0;
    top:1401px;
    height:669px;
    width:50px;
    border:1px solid blue;
    display:none;
}

.sws-sticky-child {
    position:sticky;
    /*top:0;
    height:100vh;*/
    width:100%;
    /*background-color:lightblue;*/
    /*border-bottom:5px solid orangered;*/
}

.sws-sticky-header-container {
    padding: 60px 20px 40px 20px;
    /*margin-bottom: -227px;*/
    position:relative;
    z-index:2;
    /*border-bottom: 1px solid orange;*/
}

.sws-sticky-header-msg1-container {
    /*line-height:50px;
    font-size:38px;*/
    font-weight: 600;
    margin: 0 auto 25px auto;
    max-width: 600px;
    text-align: center;
}

.sws-sticky-header-msg1 {
    display:inline-block;
}

.sws-sticky-header-msg2-container {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    /*font-weight: normal;*/
    margin: 0 auto;
    max-width: 400px;
    text-align: center;
}

.sws-sticky-content {
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    flex-direction: column;
    /*height:100vh;*/
    width:100%;
    position: relative;
    top: 0;
    /*border:1px solid blue;*/
    overflow:hidden;
}

.sws-sticky-header2-container{
    z-index: 10;
    position: absolute;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    pointer-events: none;
    padding: 60px 20px 40px 20px;
    color:#fff;
    opacity:var(--header2-opacity);
}

.sws-sticky-header2-text1-container {
    font-weight: 600;
    margin: 0 auto 25px auto;
    max-width: 600px;
    text-align: center;
    /*line-height: 50px;
    font-size:38px;*/
}

.sws-sticky-header2-text1, .sws-sticky-header2-text2 {
    display: inline-block;
    /*opacity:0;*/
}

.sws-sticky-header2-text2-container {
    margin: 0 auto;
    max-width: 400px;
    font-size:16px;
    line-height:24px;
    letter-spacing: 0;
}

.sws-sticky-backdrop-image-expander-container-wrapper {
    /*position: absolute;
    height:100%;
    width:100vw;*/
    /*original version*/
    flex:1;
    display:flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    /*border:1px solid green;*/
    /*overflow:hidden;*/
}

.sws-sticky-backdrop-image-expander-shell {
    position: absolute;
    height:100%;
    width:100vw;
    /*overflow:hidden;*/
    /*display:none;*/
}

/*
.sws-sticky-backdrop-image-expander-shell::before {
    background-color:#F8F9FA;
    content: "";
    position: absolute;
    transform:translateY(-100%);
    left: 0;
    top:0;
    width:100%;
    height:110%;
    z-index:1;
}
*/

.sws-sticky-backdrop-image-expander-container {
    position: absolute;
    top:0;
    left:0;
    /*border:2px solid red;*/
    clip-path:inset(var(--expander-clip-y) var(--expander-clip-x));
    transform:translateY(var(--expander-y)) scale(var(--expander-scale));
    transform-origin:top center;
    /*remove comments after testing
    transition:transform;
    transition-timing-function: ease;
    transition-duration: .3s;
    */
    /*original version*/
    /*transition-timing-function: ease cubic-bezier(.41, .28, .71, .13)*/;
    will-change: transform, clip-path;
    overflow: hidden;
}

.sws-sticky-backdrop-image-expander-wrapper {
    transform-origin: top center;
    will-change: transform;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.sws-sticky-backdrop-image-shelf-expander-desktop {
    background-image: url('https://server.kando-proto-2.com/large-shelf-image.jpg');
    position: relative;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background-size:cover;
    background-position: center center;
    background-repeat: no-repeat;
    opacity:1;
    transition:opacity .3s ease-in;
}

.sws-sticky-backdrop-image-shelf-expander-mobile {
    background-image: url('https://server.kando-proto-2.com/mobile-shelf-image.jpg');
    position: relative;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background-size:cover;
    background-position: center center;
    background-repeat: no-repeat;
    opacity:1;
    transition:opacity .3s ease-in;
}

.sws-sticky-backdrop-image-family-expander-desktop {
    /*background-image: url('horizontal-family-image.jpg');*/
    background-image: url('https://server.kando-proto-2.com/horizontal-family-image.jpg');
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background-size:cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.sws-sticky-backdrop-image-family-expander-mobile {
    /*background-image: url('horizontal-family-image.jpg');*/
    background-image: url('https://server.kando-proto-2.com/mobile-family-image.jpg');
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background-size:cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.swiper {
    clip-path: inset(var(--image-swipe) 0% 0% 0%);
    transform: scale(var(--image-scale));
    /*remove comments after testing
    transition: clip-path .05s linear, transform .7s linear;
    */
    /*original version*/
    /*transition-timing-function: ease-in cubic-bezier(.41, .28, .71, .13)*/;
    /*transition-timing-function: linear;
    transition-duration: .3s;*/
    will-change: transform;
}

.sws-sticky-backdrop-image-expander-container svg {
    width:100%;
}

/*need to finish image expander overlay for family image opacity*/
.sws-sticky-backdrop-image-expander-overlay{
    opacity:var(--image-blur-opacity);
    background-color:rgba(0,0,0,0.5);
    z-index: 10;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}

.sws-sticky-middle-backdrop-image-container {
    display:flex;
    justify-content: center;
    position: relative;
    align-items: flex-start;
    width:80vw;
    padding-bottom:60px;
}

.sws-sticky-left-image-container-wrapper, .sws-sticky-middle-image-container-wrapper, .sws-sticky-right-image-container-wrapper {
    margin:0 20px;
    will-change: transform;
    flex:1;
    /*border:1px solid red;*/
    /*position:relative;
    overflow:hidden;*/
    /*
    height:100%;
    width:100%;*/
}

.sws-sticky-left-image-container-wrapper, .sws-sticky-right-image-container-wrapper {
    opacity:0;
    position:relative;
    overflow:hidden;
}

.sws-sticky-left-image-container-wrapper::before, .sws-sticky-right-image-container-wrapper::before {
    content: " ";
    background-color: #f8f9fa;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 110%;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    will-change: transform,contents;
    z-index: 1;
    opacity: 0;
    transition: opacity .3s;
}

.sws-sticky-middle-image-container {
    visibility: hidden;
    opacity:0!important;
}

.sws-sticky-right-image-container, .sws-sticky-left-image-container {
    position:relative;
    height:100%;
    width:100%;
}

.sws-sticky-middle-image-container {
    position:relative;
}

.sws-sticky-left-image-container, .sws-sticky-right-image-container {
    opacity:calc(1 - (var(--stagger-factor-expand) * 5));
}

.sws-sticky-image-container {
    display:flex;
    /*border:2px solid orange;*/
    justify-content: center;
    width:80vw;
    position:relative;
    top:215px;
}

.sws-sticky-left-image-desktop, 
.sws-sticky-left-image-mobile,
.sws-sticky-middle-image-desktop,
.sws-sticky-middle-image-mobile,
.sws-sticky-right-image-desktop,
.sws-sticky-right-image-mobile {
    background-size:cover;
    background-position:center center;
    background-repeat: no-repeat;
    height:100%;
    width:100%;
    top:0;
    left:0;
    position: relative;
    opacity:1;
    transition:opacity .3s ease-in;
    display:grid;
}

.sws-sticky-left-image-desktop,
.sws-sticky-middle-image-desktop {
    background-image:url('https://server.kando-proto-2.com/left-woman-image-460.webp');
}

.sws-sticky-right-image-desktop {
    background-image:url('https://server.kando-proto-2.com/right-man-image-460.webp');    
}

.sws-sticky-left-image-mobile,
.sws-sticky-middle-image-mobile {
    background-image:url('https://server.kando-proto-2.com/left-woman-image-460.webp');
}

.sws-sticky-right-image-mobile {
    background-image:url('https://server.kando-proto-2.com/right-man-image-460.webp');    
}

.sws-sticky-image-content-wrapper {
    text-align: center;
    opacity:calc(1 - (var(--stagger-factor-expand) * 5));
}

.sws-sticky-middle-image-content-wrapper {
    text-align: center;
    opacity:calc(1 - (var(--stagger-factor-expand) * 5));
}

.sws-sticky-image-content-heading-container {
    /*line-height: 32px;*/
    /*font-size:24px;*/
    font-weight: 600;
    margin:20px 0 10px;
}

.sws-sticky-image-content-heading, .sws-sticky-image-content-caption {
    display:inline-block;
}

.sws-sticky-image-content-caption-container {
    font-size: 16px;
    line-height:24px;
    letter-spacing: 0;
    /*text-align: center;*/
}

.sws-sticky-family-image {
    background-image: url('https://server.kando-proto-2.com/horizontal-family-image.jpg');
    background-size: cover;
    background-position:center center;
    background-repeat:no-repeat;
    width:100%;
    height:100%;
    /*transform: scale(1);*/
    position: absolute;
}

.sws-sticky-bookself-image {
    background-image: url('https://server.kando-proto-2.com/large-shelf-image.jpg');
    background-size: cover;
    background-position:center center;
    background-repeat:no-repeat;
    width:100%;
    height:var(--image-swipe);
    /*transition:height .3s ease*/ /*cubic-bezier(.41, .28, .71, .13)*/;
    position: absolute;
}

/**last section styles**/
.last-section-style {
    width:100%;
    height:100vh;
    background-color:lightblue;
}

@media screen and (min-width:600px) and (max-width:1023px){
    .sws-sticky-header-msg2-container {
        max-width:456px;
        margin-left:auto;
        margin-right:auto;
    }

    .sws-sticky-header2-text2-container {
        max-width:456px;
        margin-left:auto;
        margin-right:auto;
    }

    .sws-sticky-image-content-caption-container {
        max-width: 456px;
        margin-left:auto;
        margin-right:auto;
    }
}

@media screen and (max-width:767px){
    .sws-sticky-parent{
        height:400vh;
    }

    /*
    .sws-sticky-backdrop-image-expander-shell::before {
        background-color: #f8f9fa;
        content:"";
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:110%;
        will-change:transform;
        z-index: 1;
        opacity:1;
        transform:translateY(-100%);
        transition:opacity 0s, transform 1.6s cubic-bezier(0.2, 0, 0.2, 1);
    }
    */

    .sws-sticky-backdrop-image-expander-container{
        width:100vw;
        height:100vh;
        transform:translateY(var(--expander-y-mobile)) scale(var(--expander-scale));
        /*remove comments after testing
        transition: transform .3s ease;
        */
    }

    .sws-sticky-backdrop-image-expander-wrapper {
        overflow:hidden;
        opacity:0;
    }

    .sws-sticky-backdrop-image-expander-wrapper::before {
        background-color: #f8f9fa;
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 110%;
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        will-change: transform,contents;
        z-index: 1;
        opacity: 0;
        transition: opacity .3s;
    }

    .sws-sticky-middle-backdrop-image-container {
        flex-direction: column;
    }

    .sws-sticky-header-msg1-container{
        margin:0 auto 20px auto;
        line-height:32px;
        font-size:24px;
    }

    .sws-sticky-header2-container{
        color:#3c4043;
        justify-content: flex-start;
        padding-top: calc(12vh + 60px);
        padding-right:20px;
        padding-bottom:40px;
        padding-left:20px;
    }

    .sws-sticky-backdrop-image-shelf-expander-mobile, .sws-sticky-backdrop-image-family-expander-mobile {
        position: absolute;
    }

    .sws-sticky-backdrop-image-shelf-expander-desktop, 
    .sws-sticky-backdrop-image-family-expander-desktop,
    .sws-sticky-backdrop-image-expander-overlay {
        display: none;
    }

    .sws-sticky-left-image-desktop, 
    .sws-sticky-middle-image-desktop,
    .sws-sticky-right-image-desktop {
        display:none;
    }

    .sws-sticky-middle-image-container-wrapper{
        transform:translateY(var(--tout2-mobile-y));
        order:3;
        height:100vh;
        flex:auto;
    }

    .sws-sticky-right-image-container-wrapper {
        order:2;
    }

    .sws-sticky-left-image-container-wrapper {
        order:1;
        transition: .3s opacity ease;
    }

    .inview {
        opacity:1;
    }
    
    .inview::before {
        opacity:1;
        transform:translateY(-100%);
        transition:opacity 0s, transform 1.6s cubic-bezier(0.6, 0.05, 0.1, 0.99);
    }

    .sws-sticky-middle-image-content-wrapper,
    .sws-sticky-image-content-wrapper {
        padding-bottom:80px;
    }

    .sws-sticky-header2-text1-container {
        margin: 0 auto 20px auto;
        line-height: 32px;
        font-size: 24px;
    }

    .sws-sticky-image-content-heading-container {
        line-height: 26px;
        font-size: 20px;
    }

}

@media screen and (max-width:767px) and (orientation:landscape) {
    .sws-sticky-parent {
        min-height:400vw;
    }

    .sws-sticky-header2-container {
        justify-content: center;
    }
}

@media screen and (max-width:599px) {
    .sws-sticky-header-msg2-container, .sws-sticky-header2-text2-container, .sws-sticky-image-content-caption-container, .sws-sticky-image-content-caption-container {
        max-width: calc(272px + 88 * (100vw - 360px) / 239);
        margin-left: auto;
        margin-right: auto;
    }

}

@media screen and (max-width:361px) {
    .sws-sticky-header-msg2-container, .sws-sticky-header2-text2-container, .sws-sticky-image-content-caption-container, .sws-sticky-image-content-caption-container {
        max-width: 272px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width:768px){
    .sws-sticky-parent{
        height:200vh;
        padding-bottom:80px;
    }

    .sws-sticky-child {
        top:0;
        height:100vh;
    }

    .sws-sticky-content{
        position: absolute;
        height:100vh;
        overflow:hidden;
    }

    .sws-sticky-header-container {
        padding: 70px 20px 60px 20px;
        /*margin-bottom: -227px;*/
    }

    .sws-sticky-header-msg1-container{
        line-height:3.125vw;
        font-size:2.34375vw;
    }

    .sws-sticky-header2-text1-container {
        line-height: 3.125vw;
        font-size: 2.34375vw;
    }

    .sws-sticky-header2-container {
        color:#fff;
        padding:70px 20px 60px 20px;
    }

    .sws-sticky-backdrop-image-expander-shell {
        overflow:hidden;
        opacity:0;
    }

    .sws-sticky-backdrop-image-expander-shell::before {
        background-color: #f8f9fa;
        content:"";
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:110%;
        will-change: transform;
        z-index: 1;
        opacity:0;
        transform:translateY(0%);
        transition:opacity .3s;
    }

    .sws-sticky-backdrop-image-expander-container{
        max-width: 100%;
        width:80vw;
        height:80vh;
    }

    .sws-sticky-backdrop-image-shelf-expander-mobile {
        display: none;
    }

    .sws-sticky-middle-backdrop-image-container {
        top:var(--sticky-offset-y);
        /*transform:translateY(var(--sticky-offset-y));*/
        /*transition: top .3s ease; cubic-bezier(.41, .28, .71, .13);*/
    }

    .sws-sticky-image-content-heading-container {
        line-height:2.5390625vw;
        font-size:1.953125vw;
    }

    .sws-sticky-backdrop-image-family-expander-mobile, .sws-sticky-backdrop-image-shelf-expander-mobile {
        display:none;
    }

    .sws-sticky-left-image-container-wrapper {
        transform:translateX(var(--tout1-x)) translateY(var(--tout1-y));
        /*transition:transform .3s cubic-bezier(.41, .28, .71, .13);*/
        /*remove comment after testing
        transition:transform .3s ease;
        */
        /*opacity:calc(1 - (var(--stagger-factor-expand) * 5));*/
        /*opacity:1;*/
    }

    .sws-sticky-right-image-container-wrapper {
        transform:translateX(var(--tout3-x)) translateY(var(--tout3-y));;
        /*transition:transform .3s cubic-bezier(.41, .28, .71, .13);*/
        /*remove comment after testing
        transition:transform .3s ease;
        */
        /*opacity:calc(1 - (var(--stagger-factor-expand) * 5));*/
        /*opacity:1*/
    }

    .inview, .inviews {
        opacity:1;
    }

    /*.sws-sticky-left-image-container-wrapper::before, .sws-sticky-right-image-container-wrapper::before {*/
    .inview::before{
        opacity:1;
        transform:translateY(-100%);
        transition:opacity 0s, transform .6s cubic-bezier(0.2, 0, 0.2, 1);
    }

    .inviews::before {
        opacity:1;
        transform:translateY(-100%);
        transition:opacity 0s, transform 1.6s cubic-bezier(0.2, 0, 0.2, 1);
    }

    .sws-sticky-left-image-mobile,
    .sws-sticky-middle-image-mobile,
    .sws-sticky-right-image-mobile {
        display: none;
    }

    .sws-sticky-middle-image-content-wrapper {
        /*original value was 30vh*/
        transform:translateY(calc(var(--stagger-factor-expand) * 55vh));
        /*remove comments after testing
        transition:transform .3s ease; 
        */
    }
}

@media screen and (min-width:1024px) {
    .sws-sticky-image-content-caption-container {
        max-width:524px;
        margin-left:auto;
        margin-right:auto;
    }

    .sws-sticky-header-msg2-container {
        max-width: 524px;
        margin-left:auto;
        margin-right:auto;
    }

    .sws-sticky-header2-text2-container {
        max-width:524px;
        margin-left:auto;
        margin-right:auto;
    }

}

@media screen and (min-width:1230px) {
    .sws-sticky-header-msg1-container{
        line-height:50px;
        font-size:38px;
    }

    .sws-sticky-image-content-heading {
        line-height:32px;
        font-size:24px;
    }
}