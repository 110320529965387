.video-devices-moving-container-wrapper {
    position: relative;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .video-devices-moving-desktop-container {
    display:grid;
    width:100%;
    position:relative;
    filter:brightness(var(--darken));
    overflow:hidden;
  }

  .video-devices-moving-mobile-container {
    display: none;
  }
  
  .video-devices-colors-video, .video-devices-moving {
    position: absolute;
    width:100%;
    height:100%;
    object-fit: cover;
  }
  
  .video-devices-colors-svg, .video-devices-moving-svg {
    width:100%;
  }
  
  .video-devices-moving-msg-container-wrapper {
    position: absolute;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height:100%;
    width:100%;
    /*margin-top:2.9296875vw;*/
    max-width:540px;
    padding:30px;  
    /*border:3px solid lime;*/
  }
  
  .video-devices-moving-msg-container {
    max-width: 680px;
    color:white;
    font-weight:600;
    text-align: center;
    margin-top:10px;
    margin-bottom:10px;
    /*border:2px solid yellow;*/
    transform:translateY(10%);
    transition:transform .6s .15s cubic-bezier(0.4, 0, 0.2, 1), opacity .6s .15s cubic-bezier(0.4, 0, 0.2, 1);
    opacity:1;
    /*border:1px solid orange;*/
  }
  
  .video-devices-moving-msg {
    display: inline-block;
  
  }
  
  .video-devices-moving-btn-container-wrapper {
    margin-top: 10px;
    /*border:2px solid orange;*/
    display: flex;
    justify-content: center;
    width:100%;
    transform:translateY(10%);
    transition:transform .6s .3s cubic-bezier(0.4, 0, 0.2, 1), opacity .6s .3s cubic-bezier(0.4, 0, 0.2, 1);
    opacity:1;
    /*border:1px solid yellow;*/
    line-height: 0;
  }
  
  .slide-up-video-devices-moving-msg-container {
    transform:translateY(0%);
    opacity:1;
    /*transition:transform .6s .15s cubic-bezier(0.4, 0, 0.2, 1), opacity .6s .15s cubic-bezier(0.4, 0, 0.2, 1);*/
  }
  
  .slide-up-video-devices-moving-btn-container {
    transform:translateY(0%);
    opacity:1;
    /*transition:transform .6s .3s cubic-bezier(0.4, 0, 0.2, 1), opacity .6s .3s cubic-bezier(0.4, 0, 0.2, 1);*/
  }
  
  .video-devices-moving-btn-container {
    color:#fff;
    position:relative;
  }
  
  .video-devices-moving-btn-svg {
    height:70px;
    width:70px;
    border-radius: 100%;
    cursor: pointer;
  }
  
  .video-devices-moving-btn-svg circle {
    opacity:1;
    color:#fff;
  }


  @media screen and (max-width:767px){
    .video-devices-moving-desktop-container { 
      display:none;
    }

    .video-devices-moving-mobile-container {
      display:grid;
      overflow:hidden;
      width:100%;
      position:relative;
      filter:brightness(var(--darken));
    }

    .video-devices-moving-msg-container{
      line-height:32px;
      font-size:24px;
      margin-top:0;
    }

    .video-devices-moving-btn-container-wrapper {
      margin-top: 0;
    }
  }

  @media screen and (min-width:768px){
    .video-devices-moving-msg-container-wrapper {
      max-width: 500px;
      width:38vw;
      padding:0;
    }

    .video-devices-moving-msg-container{
      line-height: 3.125vw;
      font-size:2.34375vw;
    }
  }

  @media screen and (min-width:1024px){
    .video-devices-moving-msg-container {
      margin-bottom:20px;
    }
  }

  @media screen and (min-width:1230px){
    .video-devices-moving-msg-container {
      line-height: 50px;
      font-size:38px;
    }
  }